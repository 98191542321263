import * as React from 'react';
import { useEffect, useState } from 'react'


import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField'; // Untuk input alamat
import Select from '@mui/material/Select'; // Untuk select box
import MenuItem from '@mui/material/MenuItem'; // Untuk item di dalam select box
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import LinearProgress from '@mui/material/LinearProgress';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton'; // Import IconButton
import AddBoxIcon from '@mui/icons-material/AddBox'; // Pastikan Anda sudah meng-import icon yang dibutuhkan
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import '../App.css'
import { propTypesLoading } from '@material-tailwind/react/types/components/button';
import axios from 'axios';






export default function StepperForm(props) {

  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = useState(false); // State baru untuk loading
  const [progress, setProgress] = useState(0);
  const [layanan, setLayanan] = React.useState('');

  const [tipe, setTipe] = React.useState('');
  const [tipeProduksi, setTipeProduksi] = React.useState(''); // State baru untuk Tipe Produksi
  const [lainnyaInput, setLainnyaInput] = React.useState('');

  const [error, setError] = React.useState(false); // State baru untuk error
  const [openProduksiModal, setOpenProduksiModal] = useState(false);
  const [openBbbpModal, setOpenBbbpModal] = useState(false);
  const [openNakerModal, setOpenNakerModal] = useState(false);

  const [errorKategoriProduksi, setErrorKategoriProduksi] = useState(false)
  const [errorKategoriBbbp, setErrorKategoriBbbp] = useState(false)
  const [errorKategoriNaker, setErrorKategoriNaker] = useState(false)
  const [suksesKategoriProduksi, setSuksesKategoriProduksi] = useState(false)
  const [suksesKategoriBbbp, setSuksesKategoriBbbp] = useState(false)
  const [suksesKategoriNaker, setSuksesKategoriNaker] = useState(false)

  const [etiket, setEtiket] = useState('');
  const [identitasUsahaHKI, setIdentitasUsahaHKI] = useState('');
  const [noSinasHKI, setNoSinasHKI] = useState('');
  const [npwpHKI, setNpwpHKI] = useState('');

  const [stepSatuError, setStepSatuError] = useState(false)
  const [layananError, setLayananError] = React.useState(false); // New state to track error for 'layanan'
  const [tipeError, setTipeError] = React.useState(false); // New state to track error for 'layanan'

  const [inputHkiEtiketError, setInputHkiEtiketError] = useState(false)
  const [inputHkiNibError, setInputHkiNibError] = useState(false)
  const [inputHkiSinasError, setInputHkiSinasError] = useState(false)
  const [inputHkiNpwpError, setInputHkiNpwpError] = useState(false)
  const [inputHkiIdentitasError, setInputHkiIdentitasError] = useState(false)

  // file pdf
  const [inputHkiNibFileError, setInputHkiNibFileError] = useState(false)
  const [inputHkiSinasFileError, setInputHkiSinasFileError] = useState(false)
  const [inputHkiNpwpFileError, setInputHkiNpwpFileError] = useState(false)


  const [inputNamaUsahaError, setInputNamaUsahaError] = useState(false)
  const [inputJenisProdukError, setInputJenisProdukError] = useState(false)
  const [inputAlamatUsahaError, setInputAlamatUsahaError] = useState(false)
  const [inputHargaMesinError, setInputHargaMesinError] = useState(false)
  const [inputHargaBbmError, setInputHargaBbmError] = useState(false)
  const [inputHargaListrikError, setInputHargaListrikError] = useState(false)
  const [fileNibHkiError, setFileNibHkiError] = useState(false)
  const [fileNpwpHkiError, setFileNpwpHkiError] = useState(false)
  const [fileSinasHkiError, setFileSinasHkiError] = useState(false)


  const now = new Date();

  const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
  const hari = days[now.getDay()];
  const tanggal = now.toISOString().split('T')[0];
  const waktu = now.toTimeString().split(' ')[0];
  const tahun = new Date().getFullYear();

  const [formErrors, setFormErrors] = useState({
    nib: false,
    kbli: false,
    namaUsaha: false,
    jenisProduk: false,
    alamatUsaha: false,
    hargaMesin: false,
    hargaBbm: false,
    hargaListrik: false,
  });


  const [formData, setFormData] = useState({
    layanan: '',
    tipe: '',
    tipeProduksi: '',
    nik: props.dataNik,
    nama: props.dataNama,
    alamat: props.dataAlamat,
    nowa: props.dataNowa,
    noNib: 0,
    pdfNib_filename: '',
    pdfNib_filetype: '',
    pdfNib_filesize: '',
    pdfNib_filedata: '',
    kbli: '',
    namaUsaha: '',
    jenisProduk: '',
    alamat_usaha: '',
    produksiRows: [],
    bbBpRows: [],
    nakerRows: [],
    hargaMesin: 0,
    hargaBbm: 0,
    hargaListrik: 0,
    hari: hari,
    tanggal: tanggal,
    waktu: waktu,
    tahun: tahun
  });




  //form hki


  const [dataHKI, setDataHKI] = useState({
    nik: props.dataNik,
    nama: props.dataNama,
    alamat: props.dataAlamat,
    nowa: props.dataNowa,
    etiket: '',
    noNib: 0,
    pdfNib_filename: '',
    pdfNib_filetype: '',
    pdfNib_filesize: '',
    pdfNib_filedata: '',
    noSinas: 0,
    pdfSinas_filename: '',
    pdfSinas_filetype: '',
    pdfSinas_filesize: '',
    pdfSinas_filedata: '',
    noNpwp: 0,
    pdfNpwp_filename: '',
    pdfNpwp_filetype: '',
    pdfNpwp_filesize: '',
    pdfNpwp_filedata: '',
    identitasUsaha: '',
    hari: hari,
    tanggal: tanggal,
    waktu: waktu,
    tahun: tahun
  });


  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showRetry, setShowRetry] = useState(false);
  const [errorKirim, setErrorKirim] = useState(""); // State untuk menyimpan pesan error
  const updateNetwork = () => {
    setIsOnline(navigator.onLine);
  };
  useEffect(() => {
    window.addEventListener('online', updateNetwork);
    window.addEventListener('offline', updateNetwork);

    // Bersihkan listener ketika komponen di-unmount
    return () => {
      window.removeEventListener('online', updateNetwork);
      window.removeEventListener('offline', updateNetwork);
    };
  }, []);
  window.addEventListener('online',  updateOnlineStatus);
  window.addEventListener('offline', updateOnlineStatus);
function updateOnlineStatus(event) {
  const condition = navigator.onLine ? "online" : "offline";
  console.log("Network status changed:", condition);
  // setShowRetry(true)
}  
  // interface design

  // kirim hki

  const kirimDataHki = async () => {

    if (!navigator.onLine) {
      
      console.log("Tidak ada koneksi internet. Silakan cek koneksi Anda dan coba lagi.");
      setShowRetry(true); // Menampilkan tombol untuk coba kirim ulang
      return; // Hentikan eksekusi fungsi lebih lanjut
    }
    else{
      setLoading(true);
      setProgress(0);
      setShowRetry(false);
      const data = JSON.stringify(dataHKI);
      const url = `https://bidangperindustrian.my.id/api/wait-list/hki.php`;
  
      try {
        const response = await axios.post(url, data, {
          headers: {
            'Content-Type': 'application/json',
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log('pppppp');
            if (!navigator.onLine) {

              console.log('tiba2 offline')
              setShowRetry(true)
              throw new Error("Internet terputus saat pengiriman.");
            }
            setProgress(percentCompleted);
           
         
          
          }
        });
        // if (!navigator.onLine) {
        //   console.log('tiba2 offline')
        //   setShowRetry(true)
        //   throw new Error("Internet terputus saat pengiriman.");
        // }
  
        if (response.data && response.data.status === 'success') {
          console.log('Respon diterima:', response.data);
          setProgress(100);
          console.log('Data berhasil dikirim!');
        } else {
          throw new Error(response.data.message || 'Server tidak memberikan respons yang diharapkan.');
        }
      } catch (error) {
        console.error('Error:', error);
        setErrorKirim('Gagal mengirim data: ' + (error.response?.data?.message || error.message));
        setShowRetry(true); // Menampilkan tombol kirim ulang
      } finally {
        setLoading(false);
        setProgress(0);
      }
    }
    
  };


  const kirimdatanonhki = () => {
    setLoading(true); // Mulai menampilkan loading
    setProgress(0); // Mulai progress dari 0

    // Interval untuk simulasi progress. Dalam kasus nyata, Anda mungkin ingin memperbarui ini berdasarkan
    // respons dari server atau menggunakan teknik lain seperti progress event pada XHR atau axios.


    const data = JSON.stringify(formData);
    const url = `https://bidangperindustrian.my.id/api/wait-list/insert-non-hki.php`;

    axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => {
        // console.log('ini data respon ', response);
        setProgress(100); // Setelah data berhasil dikirim, set progress ke 100%
      })
      .catch(error => {
        setLoading(true); // Hentikan loading setelah permintaan selesai (baik sukses maupun gagal)
        setProgress(0); // Opsional, tergantung pada kebutuhan UI Anda

        console.error('Error:', error);
        alert(error);
      })
      .finally(() => {
        setLoading(false); // Hentikan loading setelah permintaan selesai (baik sukses maupun gagal)
        setProgress(100); // Opsional, tergantung pada kebutuhan UI Anda

      });
  };







  const handleEtiketHKIChange = (e) => {
    setEtiket(e.target.value)
    setDataHKI({ ...dataHKI, etiket: e.target.value })
    setError(false);
    setInputHkiEtiketError(false)
  }
  const handleNoSinasHKIChange = (e) => {
    setNoSinasHKI(e.target.value)
    setDataHKI({ ...dataHKI, noSinas: e.target.value })
    setError(false);
    setInputHkiSinasError(false)
  }
  const handleNpwpHKIChange = (e) => {
    setError(false);
    setInputHkiNpwpError(false)
    setNpwpHKI(e.target.value)
    setDataHKI({ ...dataHKI, noNpwp: e.target.value })
  }
  const handleIdentitasUsahaHKIChange = (e) => {
    setIdentitasUsahaHKI(e.target.value)
    setError(false);
    setInputHkiIdentitasError(false)
    setDataHKI({ ...dataHKI, identitasUsaha: e.target.value })
  }

  //modal
  const handleOpenProduksiModal = () => setOpenProduksiModal(true);
  const handleCloseProduksiModal = () => {
    setOpenProduksiModal(false);
  }

  const handleOpenBbbpModal = () => setOpenBbbpModal(true);
  const handleCloseBbbpModal = () => setOpenBbbpModal(false);
  const handleOpenNakerModal = () => setOpenNakerModal(true);
  const handleCloseNakerModal = () => setOpenNakerModal(false);

  // nib file pdf
  const tipeProduksiOptions = ["Kerajinan", "Makanan Minuman", "Lainnya"];

  const [nibHKIFile, setNibHKIFile] = useState([]);
  const [sinasHKIFile, setSinasHKIFile] = useState([]);
  const [npwpHKIFile, setNpwpHKIFile] = useState([]);
  const [nibFile, setNibFile] = useState([]);
  const [noNib, setNoNib] = useState(null);
  const [alamatUsaha, setAlamatUsaha] = useState(null)
  const [kbli, setKbli] = useState(null)
  const [npwp, setNpwp] = useState(null)
  const [namaUsaha, setNamaUsaha] = useState(null)
  const [jenisProduk, setJenisProduk] = useState(null)
  const [hargaMesin, setHargaMesin] = useState(null)
  const [hargaBbm, setHargaBbm] = useState(null)
  const [hargaListrik, setHargaListrik] = useState(null)


  const [produksiRows, setProduksiRows] = useState([{ komoditi: null, kodeCabang: null, kbli: null, satuan: null, volumeProduksi: null, nilaiProduksi: null }]);
  const [bbBpRows, setBbBpRows] = React.useState([
    { jenis: null, satuan: null, volume: null, harga: null } // Baris pertama
  ]);
  const [nakerRows, setNakerRows] = useState([
    { jenis: null, jumlahpria: null, upahpria: null, jumlahwanita: null, upahwanita: null } // Baris pertama
  ]);
  const [npwpFile, setNpwpFile] = useState([]);
  const [sinasFile, setSinasFile] = useState([]);



  const [productionData, setProductionData] = React.useState([
    { komoditi: null, kodeCabang: null, kbli: null },
  ]);

  const handleAlamatUsahaChange = (e) => {
    setAlamatUsaha(e.target.value)
    setFormData({ ...formData, alamat_usaha: e.target.value });
    setError(false);
    setInputAlamatUsahaError(false)
  }
  const handleNpwpChange = (e) => {
    setNpwp(e.target.value)
    setFormData({ ...formData, noNpwp: e.target.value });
    setError(false);

  }


  const handleKbliChange = (e) => {
    if (e.target.value === '') {
      setKbli(null)
    }
    else {
      setKbli(e.target.value)
      setFormData({ ...formData, kbli: e.target.value });
      setError(false);
    }


  }
  const handleNamaUsahaChange = (e) => {
    setInputNamaUsahaError(false)
    setNamaUsaha(e.target.value)
    setFormData({ ...formData, namaUsaha: e.target.value });
    setInputNamaUsahaError(false)
  }


  const handleJenisProdukChange = (e) => {
    setFormData({ ...formData, jenisProduk: e.target.value });

    setJenisProduk(e.target.value)
    console.log(jenisProduk);

    setError(false);
    setInputJenisProdukError(false)

  }
  const handleHargaMesinChange = (e) => {
    setFormData({ ...formData, hargaMesin: e.target.value });

    setHargaMesin(e.target.value)
    setError(false);
    setInputHargaMesinError(false)

  }
  const handleHargaBbmChange = (e) => {
    setFormData({ ...formData, hargaBbm: e.target.value });

    setHargaBbm(e.target.value)
    setError(false);
    setInputHargaBbmError(false)
  }
  const handleHargaListrikChange = (e) => {
    setFormData({ ...formData, hargaListrik: e.target.value });
    setHargaListrik(e.target.value)
    setError(false);
    setInputHargaListrikError(false)
  }


  const handleHKINibFileChange = (event) => {
    setError(false);

    const file = event.target.files[0];
    if (!file) {
      console.log('Tidak ada file yang dipilih.');
      return; // Hentikan fungsi jika tidak ada file yang dipilih
    }
    setNibHKIFile(file)
    const fileSizeInMB = file.size / (1024 * 1024); // Mengonversi ukuran file ke MB

    if (file.type === "application/pdf" && fileSizeInMB <= 5) {
      const reader = new FileReader();

      reader.onload = function (loadEvent) {
        const base64Data = loadEvent.target.result;
        const sizeData = file.size / (1024 * 1024);
        const ukuranFile = sizeData.toFixed(2);

        console.log(`Ukuran file adalah, ${ukuranFile} Mb`);
        console.log('File PDF kepemilikan NIB berhasil diunggah:', file);

        // Menyimpan nama file, ukuran, tipe, dan data Base64 dari file PDF ke state
        setDataHKI({
          ...dataHKI,
          pdfNib_filename: file.name,
          pdfNib_filetype: file.type,
          pdfNib_filesize: `${ukuranFile} Mb`,
          pdfNib_filedata: base64Data // Menyimpan data file sebagai string Base64
        });
      };

      // Membaca file sebagai data URL (Base64)
      reader.readAsDataURL(file);
    }
    else if (fileSizeInMB > 5) {
      console.log('File tidak boleh melebihi batas maksimum 5MB.');
      // Set error state atau tampilkan pesan error ke pengguna di sini
    }
    else {
      console.log('File yang diunggah bukan PDF.');
    }
  };
  const handleHKISinasFileChange = (event) => {
    setError(false);

    const file = event.target.files[0];
    if (!file) {
      console.log('Tidak ada file yang dipilih.');
      return; // Hentikan fungsi jika tidak ada file yang dipilih
    }
    setSinasHKIFile(file)
    const fileSizeInMB = file.size / (1024 * 1024); // Mengonversi ukuran file ke MB
    if (file.type === "application/pdf" && fileSizeInMB <= 5) {
      const reader = new FileReader();

      reader.onload = function (loadEvent) {
        const base64Data = loadEvent.target.result;
        const sizeData = file.size / (1024 * 1024);
        const ukuranFile = sizeData.toFixed(2);

        console.log(`Ukuran file adalah, ${ukuranFile} Mb`);
        console.log('File PDF kepemilikan NIB berhasil diunggah:', file);

        // Menyimpan nama file, ukuran, tipe, dan data Base64 dari file PDF ke state
        setDataHKI({
          ...dataHKI,
          pdfSinas_filename: file.name,
          pdfSinas_filetype: file.type,
          pdfSinas_filesize: `${ukuranFile} Mb`,
          pdfSinas_filedata: base64Data // Menyimpan data file sebagai string Base64
        });
      };

      // Membaca file sebagai data URL (Base64)
      reader.readAsDataURL(file);
    }
    else if (fileSizeInMB > 5) {
      console.log('File tidak boleh melebihi batas maksimum 5MB.');
    }
    else {
      console.log('File yang diunggah bukan PDF.');
    }
  };
  // const handleHKISinasFileChange = (event) => {
  //   setError(false);

  //   const file = event.target.files[0];
  //   if (file && file.type === "application/pdf") {
  //     setSinasHKIFile(file)
  //     const reader = new FileReader();

  //     reader.onload = function (loadEvent) {
  //       const base64Data = loadEvent.target.result;
  //       const sizeData = file.size / (1024 * 1024);
  //       const ukuranFile = sizeData.toFixed(2);

  //       console.log(`Ukuran file adalah, ${ukuranFile} Mb`);
  //       console.log('File PDF kepemilikan NIB berhasil diunggah:', file);

  //       // Menyimpan nama file, ukuran, tipe, dan data Base64 dari file PDF ke state
  //       setDataHKI({
  //         ...dataHKI,
  //         pdfSinas_filename: file.name,
  //         pdfSinas_filetype: file.type,
  //         pdfSinas_filesize: `${ukuranFile} Mb`,
  //         pdfSinas_filedata: base64Data // Menyimpan data file sebagai string Base64
  //       });
  //     };

  //     // Membaca file sebagai data URL (Base64)
  //     reader.readAsDataURL(file);
  //   } else {
  //     console.log('File yang diunggah bukan PDF.');
  //   }
  // };
  const handleHKINpwpFileChange = (event) => {
    setError(false);

    const file = event.target.files[0];
    if (!file) {
      console.log('Tidak ada file yang dipilih.');
      return; // Hentikan fungsi jika tidak ada file yang dipilih
    }
    setNpwpHKIFile(file)
    const fileSizeInMB = file.size / (1024 * 1024); // Mengonversi ukuran file ke MB

    if (file.type === "application/pdf" && fileSizeInMB <= 5) {
      const reader = new FileReader();

      reader.onload = function (loadEvent) {
        const base64Data = loadEvent.target.result;
        const sizeData = file.size / (1024 * 1024);
        const ukuranFile = sizeData.toFixed(2);

        console.log(`Ukuran file adalah, ${ukuranFile} Mb`);
        console.log('File PDF kepemilikan NIB berhasil diunggah:', file);

        // Menyimpan nama file, ukuran, tipe, dan data Base64 dari file PDF ke state
        setDataHKI({
          ...dataHKI,
          pdfNpwp_filename: file.name,
          pdfNpwp_filetype: file.type,
          pdfNpwp_filesize: `${ukuranFile} Mb`,
          pdfNpwp_filedata: base64Data // Menyimpan data file sebagai string Base64
        });
      };

      // Membaca file sebagai data URL (Base64)
      reader.readAsDataURL(file);
    } else if (fileSizeInMB > 5) {
      console.log('File tidak boleh melebihi batas maksimum 5MB.');
      // Set error state atau tampilkan pesan error ke pengguna di sini
    }
    else {
      console.log('File yang diunggah bukan PDF.');
    }
  };


  const handleNibFileChange = (event) => {
    setError(false);

    const file = event.target.files[0];
    if (!file) {
      console.log('Tidak ada file yang dipilih.');
      return; // Hentikan fungsi jika tidak ada file yang dipilih
    }

    setNibFile(file);

    const fileSizeInMB = file.size / (1024 * 1024); // Mengonversi ukuran file ke MB
    if (file.type === "application/pdf" && fileSizeInMB <= 5) {
      const reader = new FileReader();

      reader.onload = function (loadEvent) {
        const base64Data = loadEvent.target.result;
        const ukuranFile = fileSizeInMB.toFixed(2);

        console.log(`Ukuran file adalah, ${ukuranFile} Mb`);
        console.log('File PDF kepemilikan NIB berhasil diunggah:', file);

        // Menyimpan nama file, ukuran, tipe, dan data Base64 dari file PDF ke state
        setFormData({
          ...formData,
          pdfNib_filename: file.name,
          pdfNib_filetype: file.type,
          pdfNib_filesize: `${ukuranFile} Mb`,
          pdfNib_filedata: base64Data // Menyimpan data file sebagai string Base64
        });
      };

      // Membaca file sebagai data URL (Base64)
      reader.readAsDataURL(file);
    } else if (fileSizeInMB > 5) {
      console.log('File tidak boleh melebihi batas maksimum 5MB.');
      // Set error state atau tampilkan pesan error ke pengguna di sini
    } else {
      console.log('File yang diunggah bukan PDF.');
      // Set error state atau tampilkan pesan error ke pengguna di sini
    }
  };


  // sinas file pdf
  const handleSinasFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setSinasFile(file); // Asumsi setNibFile adalah state setter untuk menyimpan file
      console.log('File PDF kepemilikan Sinas berhasil diunggah:', file);
    } else {
      console.log('File yang diunggah bukan PDF.');
    }
  };

  // npwp file pdf
  const handleNpwpFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setNpwpFile(file); // Asumsi setNibFile adalah state setter untuk menyimpan file
      console.log('File PDF kepemilikan NPWP berhasil diunggah:', file);
    } else {
      console.log('File yang diunggah bukan PDF.');
    }
  };


  // kategori produksi
  const handleChangeProduksi = (index, field, value) => {
    const updatedRows = [...produksiRows];
    updatedRows[index][field] = value;
    setProduksiRows(updatedRows);
    setError(false);
    setErrorKategoriProduksi(false)
  };
  const addProduksiRow = () => {
    setProduksiRows([...produksiRows, { komoditi: '', kodeCabang: '', kbli: '', satuan: '', nilaiProduksi: '' }]);
  };

  const handleSaveProduksiData = () => {
    if (produksiRows.every(row => row.komoditi && row.kodeCabang && row.kbli && row.volumeProduksi && row.nilaiProduksi)) {
      setSuksesKategoriProduksi(true)
      setErrorKategoriProduksi(false)

      setOpenProduksiModal(false);
      // Mengumpulkan semua data produksi dalam sebuah objek
      const dataProduksi = { produksi: produksiRows };
      setFormData({
        ...formData,
        produksiRows: dataProduksi,
      });
      // Lakukan apa yang perlu Anda lakukan dengan dataProduksi, misalnya kirim ke backend
      console.log('ini data produksi ', dataProduksi);
    }

  };
  const handleRemoveProduksiRow = (index) => {
    setProduksiRows(produksiRows.filter((_, i) => i !== index));
  };
  // kategori bb/bp
  const handleChangeBbbp = (index, field, value) => {
    const updatedRows = [...bbBpRows];
    updatedRows[index][field] = value;
    setBbBpRows(updatedRows);
    setError(false);
    setErrorKategoriBbbp(false)

  };

  const addBbbpRow = () => {
    setBbBpRows([...bbBpRows, { jenis: '', satuan: '', volume: '', harga: '' }]);
  };
  const handleSaveBbbpData = () => {
    // Periksa apakah ada setidaknya satu baris yang diisi dengan lengkap
    const isAtLeastOneRowComplete = bbBpRows.some(row => row.jenis && row.satuan && row.volume && row.harga);

    if (isAtLeastOneRowComplete) {
      // Jika setidaknya satu baris diisi dengan lengkap, proses data
      setErrorKategoriBbbp(false);
      setSuksesKategoriBbbp(true);

      // Tutup modal
      setOpenBbbpModal(false);

      // Siapkan data untuk dikirim atau diproses lebih lanjut
      const dataBbbp = { bbbp: bbBpRows };
      console.log('ini data bbbp ', dataBbbp);

      // Simpan data ke state formData (atau kirim ke backend)
      setFormData({
        ...formData,
        bbBpRows: dataBbbp
      });



    } else {
      // Jika tidak ada baris yang diisi dengan lengkap, tampilkan alert peringatan
      alert('Mohon isi setidaknya satu baris bahan baku/bahan penolong dengan lengkap.');
    }
  };

  const handleRemoveBbbpRow = (index) => {
    setBbBpRows(bbBpRows.filter((_, i) => i !== index));
  };
  // kategori naker
  const handleChangeNaker = (index, field, value) => {
    const updatedRows = [...nakerRows];
    updatedRows[index][field] = value;
    setNakerRows(updatedRows);
    setError(false);
    setErrorKategoriNaker(false)
    // setFormData({ ...formData, nakerRows: event.target.value });
  };


  const addNakerRow = () => {
    setNakerRows([...nakerRows, { jenis: '', jumlahpria: '', upahpria: '', jumlahwanita: '', upahwanita: '' }]);
  };
  const handleSaveNakerData = () => {
    if (nakerRows.some(row => (row.jenis && row.jumlahpria && row.upahpria) || (row.jenis && row.jumlahwanita && row.upahwanita))) {
      if (nakerRows.every(row => (row.jenis && row.jumlahpria && row.upahpria) || (row.jenis && row.jumlahwanita && row.upahwanita)
      )) {
        setSuksesKategoriNaker(true)
        setErrorKategoriNaker(false)
        setOpenNakerModal(false)
        const dataNaker = { naker: nakerRows };

        // Lakukan apa yang perlu Anda lakukan dengan dataProduksi, misalnya kirim ke backend

        setFormData({
          ...formData,
          nakerRows: dataNaker
        })
        console.log('data naker tersimpan');
      }
      setSuksesKategoriNaker(true)
      setErrorKategoriNaker(false)
      setOpenNakerModal(false)
      const dataNaker = { naker: nakerRows };

      // Lakukan apa yang perlu Anda lakukan dengan dataProduksi, misalnya kirim ke backend

      setFormData({
        ...formData,
        nakerRows: dataNaker
      })
      console.log('data naker tersimpan');
      console.log('user telah mengisi setidaknya satu baris');
    }


  }
  const handleRemoveNakerRow = (index) => {
    setNakerRows(nakerRows.filter((_, i) => i !== index));
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddRow = () => {
    setProduksiRows([...produksiRows, { komoditi: '', kodeCabang: '', kbli: '', volumeProduksi: '', satuan: '', nilaiProduksi: '' }]);
  };




  //produksi
  const handleSaveData = () => {
    // Simulasikan penyimpanan data (Anda perlu mengimplementasikan logika sesuai kebutuhan)
    console.log('Data disimpan:', produksiRows);

    // Tampilkan pesan atau lakukan tindakan setelah data disimpan
    alert('Data telah berhasil disimpan!');
    handleCloseModal(); // Tutup modal setelah simpan data
  };


  // handle nib
  const handleNoNibHKIChange = (e) => {
    setNoNib(e.target.value)
    setDataHKI({ ...dataHKI, noNib: e.target.value })
    setError(false);
    setInputHkiNibError(false)
  }
  const handleNoNibChange = (e) => {
    if (e.target.value === "") {
      setNoNib(null)
    }
    else {
      setNoNib(e.target.value)
      setFormData({ ...formData, noNib: e.target.value });
      setError(false);

    }
    setFormErrors({ ...formErrors, [name]: false });
  }


  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };



  const handleAddProduksiRows = () => {
    // Cek apakah baris terakhir memiliki setidaknya satu field yang kosong
    const lastRow = produksiRows[produksiRows.length - 1];
    if (!lastRow.komoditi || !lastRow.kodecabang || !lastRow.harga) {
      alert("Mohon lengkapi semua input pada baris terakhir sebelum menambah baris baru.");
      return; // Hentikan fungsi jika ada field yang kosong
    }

    // Jika semua input pada baris terakhir sudah diisi, tambahkan baris baru
    setProduksiRows([...produksiRows, { komoditi: '', kodecabang: '', harga: '' }]);
  };
  const handleAddBbBpRow = () => {
    // Cek apakah baris terakhir memiliki setidaknya satu field yang kosong
    const lastRow = bbBpRows[bbBpRows.length - 1];
    if (!lastRow.jenis || !lastRow.jumlah || !lastRow.harga) {
      alert("Mohon lengkapi semua input pada baris terakhir sebelum menambah baris baru.");
      return; // Hentikan fungsi jika ada field yang kosong
    }

    // Jika semua input pada baris terakhir sudah diisi, tambahkan baris baru
    setBbBpRows([...bbBpRows, { jenis: '', jumlah: '', harga: '' }]);
  };


  const handleKategoriProduksiChange = (index, field, event) => {
    const newKategoriProduksiRows = [...produksiRows];
    newKategoriProduksiRows[index][field] = event.target.value;
    setProduksiRows(newKategoriProduksiRows);
  };
  // function produksi
  const handleProduksiChange = (index, field, event) => {
    const newRows = [...produksiRows];
    newRows[index][field] = event.target.value;
    setProduksiRows(newRows);
  };

  const deleteProduksiRow = (index) => {
    const newRows = [...produksiRows];
    newRows.splice(index, 1);
    setProduksiRows(newRows);
  };
  const handleBbBpInputChange = (index, field, event) => {
    const newBbBpRows = [...bbBpRows];
    newBbBpRows[index][field] = event.target.value;
    setBbBpRows(newBbBpRows);
  };


  const tipeOptions = {
    Pelatihan: ["Optimasi Sosial Media", "Optimasi Marketplace", "Adsense Instagram dan TikTok", "Branding", "Digital Marketing", "Foto Produk", "Industri Bersih", "Proses Produksi"],
    Fasilitasi: ["Legalitas", "Izin Edar BPOM", "Uji Nutrisi", "Lainnya"],
    Sertifikasi: ["HKI", "Halal", "SNI", "ISO", "POPAL", "Lainnya"]
  };

  // const handleNext = () => {
  //   if (activeStep === 0 && !layanan) {
  //     console.log('User tidak mengisi data di step 0 dengan benar');
  //     setLayananError(true); // Set error true for layanan
  //     return; // Stop the function if layanan is not selected
  //   }
  //   else if (activeStep === 0 && !tipe) {
  //     setTipeError(true)
  //   }
  //   else {
  //     setLayananError(false); // Reset layanan error state if everything is okay
  //   }
  //   // Pengecekan untuk memastikan pilihan telah dipilih
  //   if (activeStep === 0 && (!layanan || !tipe || (layanan === 'Pelatihan' && tipe === 'Proses Produksi' && !tipeProduksi))) {
  //     console.log('user tidak mengisi data di step 0 dengan benar');
  //     setStepSatuError(true)
  //     setError(true); // Tampilkan error jika pilihan tidak lengkap
  //     return; // Hentikan fungsi
  //   }

  //   if (activeStep === 1) {
  //     // Ensure that produksiRows are filled for certain conditions
  //     if (layanan === 'Pelatihan' && tipe === 'Proses Produksi') {
  //       const isProduksiRowsComplete = produksiRows.every(row => row.komoditi && row.kodeCabang && row.kbli && row.volumeProduksi && row.nilaiProduksi);
  //       if (!isProduksiRowsComplete) {
  //         console.log('Produksi rows are incomplete.');
  //         setError(true); // Show an error message or indicator
  //         return; // Prevent moving to the next step
  //       }
  //     }
  //     setError(false); // If everything is okay, reset error state
  //   }


  //   if (activeStep === 1 && layanan === 'Sertifikasi' && tipe === 'HKI') {

  //     console.log('ini nonib', noNib)
  //     console.log('ini kbli', kbli)
  //     console.log('jenis[roduk', jenisProduk)
  //     console.log('npwpHKIFile', npwpHKIFile)
  //     console.log('sinasHKIFile', sinasHKIFile)
  //     console.log('nibHKIFile', nibHKIFile)
  //     console.log('user memilih form HKI')
  //     const isHKIComplete = etiket && identitasUsahaHKI && npwpHKI && noNib && npwpHKIFile && sinasHKIFile && nibHKIFile;
  //     if (!isHKIComplete) {
  //       console.log('ada error pada step 1');
  //       console.log('ini isi hki complete')
  //       setError(true);
  //       return;
  //     }
  //     else {
  //       console.log(activeStep);
  //       setError(false)
  //     }
  //   }
  //   else if (activeStep === 1 && layanan !== 'Sertifikasi' || (layanan === 'Sertifikasi' && tipe !== 'HKI')) {
  //     console.log('user memilih selain hki');
  //   }
  //   else if (activeStep === 1 && layanan === 'Pelatihan' && tipe === 'Proses Produksi') {
  //     // console.log('user memilih pelatihan dan proses produksi');
  //     // console.log('ini data proses produksi noNib', noNib)
  //     // console.log('ini data proses produksi kbli', kbli)
  //     // console.log('ini data proses produksi namaUsaha', namaUsaha)
  //     // console.log('ini data proses produksi jenisProduk', jenisProduk)
  //     // console.log('ini data proses produksi alamatUsaha', alamatUsaha)
  //     // console.log('ini data proses produksi produksiRows', produksiRows)
  //     // console.log('ini data proses produksi bbBpRows', bbBpRows)
  //     // console.log('ini data proses produksi nakerRows', nakerRows)
  //     // console.log('ini data proses produksi hargaMesin', hargaMesin)
  //     // console.log('ini data proses produksi hargaBbm', hargaBbm)
  //     // console.log('ini data proses produksi hargaListrik', hargaListrik)
  //     const dataIsComplete = noNib && kbli && namaUsaha && jenisProduk && alamatUsaha && produksiRows.length === 0 && bbBpRows.length === 0 && nakerRows.length === 0 && hargaMesin && hargaBbm && hargaListrik
  //     if (!dataIsComplete) {
  //       console.log('data belum lengkap');
  //       setError(true);
  //       return;
  //     }
  //     else {
  //       console.log(activeStep);
  //       setError(false)
  //     }
  //   }

  //   if (activeStep === 2 && layanan === "Sertifikasi" && tipe === "HKI") {
  //     kirimDataHki()
  //   }

  //   if (activeStep === 2 && layanan !== "Sertifikasi" && tipe !== "HKI") {

  //     console.log('user memilih non hki');
  //     kirimdatanonhki()
  //   }
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };



  const handleNext = () => {
    // Reset error states at the beginning of the function
    setError(false);
    setLayananError(false);
    setTipeError(false);
    setStepSatuError(false);
    setErrorKategoriProduksi(false)
    setErrorKategoriBbbp(false)
    setErrorKategoriNaker(false)
    setSuksesKategoriProduksi(false)
    setSuksesKategoriBbbp(false)
    setSuksesKategoriNaker(false)

    // Handling validation for Step 0
    if (activeStep === 0) {
      if (!layanan) {
        console.log('Layanan is required.');
        setLayananError(true);
        return;
      } else if (!tipe) {
        console.log('Tipe is required.');
        setTipeError(true);
        return;
      } else if (layanan === 'Pelatihan' && tipe === 'Proses Produksi' && !tipeProduksi) {
        console.log('Tipe Produksi is required for Pelatihan > Proses Produksi.');
        setError(true); // Assuming setError shows a generic error message
        return;
      }
    }

    // Handling validation for Step 1
    if (activeStep === 1) {
      // Specific validation for Pelatihan > Proses Produksi that requires filling in produksiRows
      if (layanan === 'Pelatihan' && tipe === 'Proses Produksi') {
        console.log('user memilih bukan proses produksi');
        let errorFound = false;
        const kategoriNib = noNib && nibFile === 0
        const isDataComplete = namaUsaha && jenisProduk && alamatUsaha && hargaMesin && hargaBbm && hargaListrik
        const isProduksiRowsComplete = produksiRows.every(row => row.komoditi && row.kodeCabang && row.kbli && row.volumeProduksi && row.nilaiProduksi);





        if (noNib && !nibFile) {
          console.log('Nomor NIB diisi tetapi file NIB belum diunggah. Silakan unggah file NIB untuk melanjutkan.');
          return; // Hentikan fungsi jika file NIB belum diunggah tetapi nomor NIB diisi
        }
        if (!namaUsaha) {
          errorFound = true
          setInputNamaUsahaError(true)
          console.log('wajib isi nama usaha');
        }
        if (!jenisProduk) {
          errorFound = true
          setInputJenisProdukError(true)
          console.log('wajib isi nama usaha');
        }
        if (!alamatUsaha) {
          errorFound = true
          setInputAlamatUsahaError(true)
          console.log('wajib isi nama usaha');
        }
        if (!hargaMesin) {
          errorFound = true
          setInputHargaMesinError(true)
          console.log('wajib isi nama usaha');
        }
        if (!hargaBbm) {
          errorFound = true
          setInputHargaBbmError(true)
          console.log('wajib isi nama usaha');
        }
        if (!hargaListrik) {
          errorFound = true
          setInputHargaListrikError(true)
          console.log('wajib isi nama usaha');
        }
        if (!isDataComplete || !kategoriNib) {
          console.log('data selain kategori belum lengkap');
          errorFound = true
        }
        else {
          console.log('data selain kategori telah lengkap');

          errorFound = false
        }
        if (!isProduksiRowsComplete) {
          setErrorKategoriProduksi(true);
          errorFound = true;
        } else {
          setSuksesKategoriProduksi(true);
        }

        // Cek kategori BB/BP
        const isBbbpRowsComplete = bbBpRows.every(row => row.jenis && row.satuan && row.volume && row.harga);
        if (!isBbbpRowsComplete) {
          setErrorKategoriBbbp(true);
          errorFound = true;
        } else {
          setSuksesKategoriBbbp(true);
        }

        // Cek kategori Naker
        const isNakerRowsComplete = nakerRows.some(row => (row.jenis && row.jumlahpria && row.upahpria) || (row.jenis && row.jumlahwanita && row.upahwanita));
        if (!isNakerRowsComplete) {
          setErrorKategoriNaker(true);
          errorFound = true;
        } else {
          setSuksesKategoriNaker(true);
        }

        // Jika ada error, hentikan proses lanjut ke step berikutnya
        if (errorFound) return;

      }

      // Specific validation for Sertifikasi > HKI
      if (layanan === 'Sertifikasi' && tipe === 'HKI') {
        const areFilesUploaded = npwpHKIFile.length !== 0 && sinasHKIFile.length !== 0 && nibHKIFile.length !== 0;
        const isHKIComplete = etiket && identitasUsahaHKI && npwpHKI && noNib;
        let adaError = false
        if (!etiket) {
          adaError = true
          setInputHkiEtiketError(true)
        }
        if (!noNib) {
          adaError = true
          setInputHkiNibError(true)
        }
        if (!noSinasHKI) {
          adaError = true
          setInputHkiSinasError(true)
        }
        if (!npwpHKI) {
          adaError = true
          setInputHkiNpwpError(true)
        }
        if (!identitasUsahaHKI) {
          adaError = true
          setInputHkiIdentitasError(true)
        }
        if (!isHKIComplete || !areFilesUploaded) {
          console.log('HKI form is incomplete.');
          return;
        }
        if (adaError) return
      }
      else {
        // handle nonib
        const isDataComplete = namaUsaha && jenisProduk && alamatUsaha && hargaMesin && hargaBbm && hargaListrik
        const kategoriNib = noNib && nibFile.length !== 0
        const isProduksiRowsComplete = produksiRows.every(row => row.komoditi && row.kodeCabang && row.kbli && row.volumeProduksi && row.nilaiProduksi);
        let errorFound = false;

        if (!jenisProduk) {
          errorFound = true
        }
        if (!namaUsaha) {
          errorFound = true
          setInputNamaUsahaError(true)
          console.log('wajib isi nama usaha');
        }
        if (!jenisProduk) {
          errorFound = true
          setInputJenisProdukError(true)
          console.log('wajib isi nama usaha');
        }
        if (!alamatUsaha) {
          errorFound = true
          setInputAlamatUsahaError(true)
          console.log('wajib mengisi nama usaha');
        }
        if (!hargaMesin) {
          errorFound = true
          setInputHargaMesinError(true)
          console.log('wajib mengisi nama usaha');
        }
        if (!hargaBbm) {
          errorFound = true
          setInputHargaBbmError(true)
          console.log('wajib mengisi nama usaha');
        }
        if (!hargaListrik) {
          errorFound = true
          setInputHargaListrikError(true)
          console.log('wajib menisi nama usaha');
        }
        if (!isDataComplete || !kategoriNib) {
          console.log('data selain kategori belum lengkap');
          errorFound = true
        }
        console.log('user memilih bukan proses produksi');



        if (noNib !== null && !nibFile) {
          console.log('Nomor NIB diisi tetapi file NIB belum diunggah. Silakan unggah file NIB untuk melanjutkan.');
          return; // Hentikan fungsi jika file NIB belum diunggah tetapi nomor NIB diisi
        }

        if (!isDataComplete) {
          console.log('data selain kategori belum lengkap');
          errorFound = true
        }
        else {
          console.log('data selain kategori telah lengkap');
          errorFound = false
        }
        if (!isProduksiRowsComplete) {
          setErrorKategoriProduksi(true);
          errorFound = true;
        } else {
          setSuksesKategoriProduksi(true);
        }

        // Cek kategori BB/BP
        const isBbbpRowsComplete = bbBpRows.every(row => row.jenis && row.satuan && row.volume && row.harga);
        if (!isBbbpRowsComplete) {
          setErrorKategoriBbbp(true);
          errorFound = true;
        } else {
          setSuksesKategoriBbbp(true);
        }

        // Cek kategori Naker
        const isNakerRowsComplete = nakerRows.some(row => (row.jenis && row.jumlahpria && row.upahpria) || (row.jenis && row.jumlahwanita && row.upahwanita));
        if (!isNakerRowsComplete) {
          setErrorKategoriNaker(true);
          errorFound = true;
        } else {
          setSuksesKategoriNaker(true);
        }
        if (errorFound) {
          return;
        }
        // if (!isProduksiRowsComplete) {
        //   setErrorKategoriProduksi(true)
        // }
        // else {
        //   setSuksesKategoriProduksi(true)
        // }
        // if (!isBbbpRowsComplete) {
        //   setErrorKategoriBbbp(true)
        // }
        // else {
        //   setSuksesKategoriBbbp(true)
        // }
        // if (!isNakerRowsComplete) {
        //   setErrorKategoriNaker(true)
        // }
        // else {
        //   setSuksesKategoriNaker(true)
        // }


        // const dataIsComplete = nibFile.length !== 0 && noNib && kbli && namaUsaha && jenisProduk && alamatUsaha && produksiRows.length !== 0 && bbBpRows.length !== 0 && nakerRows.length !== 0 && hargaMesin && hargaBbm && hargaListrik
        // if (!dataIsComplete) {
        //   console.log('data belum lengkap user memilih selain hki');
        //   setError(true);
        //   setErrorKategoriProduksi(true)
        //   setErrorKategoriBbbp(true)
        //   setErrorKategoriNaker(true)
        //   return;
        // }
        // else {
        //   console.log(activeStep);
        //   setSuksesKategoriProduksi(true)
        //   setSuksesKategoriBbbp(true)
        //   setSuksesKategoriNaker(true)

        //   setError(false)
        // }
        // console.log('user memilih selain hki dan juga selain layanan sertifikasi');
      }
      // Add additional else-if blocks here for other specific validations for Step 1
    }



    // Handling submit
    if (activeStep === 2) {
      if (layanan === "Sertifikasi" && tipe === "HKI") {
        // Submit HKI data
        console.log('User memilih layanan HKI');

        kirimDataHki();
      } else {
        // Submit non-HKI data
        console.log('User memilih layanan selain HKI atau tipe layanan selain Sertifikasi.');

        kirimdatanonhki();
      }

    }
    // else if (activeStep === 1 && layanan !== 'Sertifikasi' || (layanan === 'Sertifikasi' && tipe !== 'HKI')) {
    //   // Handle actions for non-HKI selections here
    //   console.log('User memilih layanan selain HKI atau tipe layanan selain Sertifikasi.');
    //   // You can add any specific logic here, for example, setting a state, showing a message, etc.
    //   // For demonstration, let's just log a message. You can replace this with your actual logic.
    // }

    // Proceed to the next step if all validations pass
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    console.log(`user menuju ke ${activeStep}`);
  };

  const handleReset = () => {
    setActiveStep(0);
    setLayanan('');
    setTipe('');
    setTipeProduksi(''); // Reset state Tipe Produksi
    setError(false);
  };
  const handleLayananChange = (event) => {
    setLayanan(event.target.value);
    setError(false); // Tampilkan error jika pilihan tidak lengkap
    setFormData({ ...formData, layanan: event.target.value });
    setLayananError(false);
    setStepSatuError(false)
    setTipe(''); // Reset tipe ketika layanan berubah
    setTipeProduksi(''); // Reset Tipe Produksi
  };

  const handleTipeChange = (event) => {
    setTipe(event.target.value);
    setError(false); // Tampilkan error jika pilihan tidak lengkap
    setFormData({ ...formData, tipe: event.target.value });
    setTipeError(false)
    setTipeProduksi(''); // Reset Tipe Produksi saat tipe berubah
  };
  const handleTipeProduksiChange = (event) => {
    setTipeProduksi(event.target.value);
  };


  const handleProductionInputChange = (e, index, field) => {
    const updatedData = [...productionData];
    updatedData[index][field] = e.target.value;
    setProductionData(updatedData);
  };


  const steps = [
    {
      label: 'Silahkan memilih kategori layanan',
      content: (
        <React.Fragment>
          <FormControl fullWidth margin="normal">
            <InputLabel id="layanan-label">Pilih Layanan</InputLabel>
            <Select
              labelId="layanan-label"
              id="layanan-select"
              value={layanan}
              onChange={handleLayananChange}
              label="Pilih Layanan"
            >
              <MenuItem value="Pelatihan">Pelatihan</MenuItem>
              <MenuItem value="Fasilitasi">Fasilitasi</MenuItem>
              <MenuItem value="Sertifikasi">Sertifikasi</MenuItem>
            </Select>
            {layananError && <FormHelperText style={{ color: 'red' }}>Error: Silahkan memilih kategori layanan</FormHelperText>}
          </FormControl>
          <FormControl fullWidth margin="normal" disabled={!layanan}>
            <InputLabel id="tipe-label">Pilih Tipe</InputLabel>
            <Select
              labelId="tipe-label"
              id="tipe-select"
              value={tipe}
              onChange={handleTipeChange}
              label="Pilih Tipe"
            >
              {layanan && tipeOptions[layanan].map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
            {tipeError && <FormHelperText style={{ color: 'red' }}>Error: Silahkan memilih tipe layanan</FormHelperText>}

          </FormControl>
          {layanan === 'Pelatihan' && tipe === 'Proses Produksi' && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="tipe-produksi-label">Tipe Produksi</InputLabel>
              <Select
                labelId="tipe-produksi-label"
                id="tipe-produksi-select"
                value={tipeProduksi}
                onChange={handleTipeProduksiChange}
                label="Tipe Produksi"
              >
                {tipeProduksiOptions.map((option) => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
              {/* {tipeProduksi === "Lainnya" && (
                <TextField
                  fullWidth
                  margin="normal"
                  label="Pelayanan"
                  variant="outlined"
                  value={lainnyaInput}
                  onChange={(e) => setLainnyaInput(e.target.value)}
                />
              )} */}
            </FormControl>
          )}

        </React.Fragment>
      ),
    },
    {
      label: 'Rincian Data',
      content:
        layanan === "Sertifikasi" && tipe === "HKI" ? (
          // tipe hki
          <React.Fragment>
            <TextField error={inputHkiEtiketError} helperText={inputHkiEtiketError ? "Wajib mengisi etiket" : ''} onChange={handleEtiketHKIChange} value={etiket} fullWidth margin="normal" label="Etiket merek/logo" variant="outlined" />
            <TextField error={inputHkiNibError} helperText={inputHkiNibError ? "Wajib mengisi nomor Nib" : ''} onChange={handleNoNibHKIChange} value={noNib} fullWidth margin="normal" label="Nomor NIB" variant="outlined" />
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel shrink htmlFor="kepemilikan-nib-upload">Bukti Kepemilikan NIB (*pdf maks 5Mb)</InputLabel>
              <input
                accept="application/pdf"
                id="kepemilikan-nib-hki-upload"
                type="file"
                style={{ display: 'none' }}
                onChange={handleHKINibFileChange}
              />
              {/* {nibHKIFile.length !== 0 ? (<Alert severity="success">{nibHKIFile.name}</Alert>) : ""} */}
              {/* {nibHKIFile && <Alert severity="success">{nibHKIFile.name}</Alert>} */}
              {/* {nibHKIFile.type !== "application/pdf" && nibHKIFile.length !== 0 ? (<Alert severity="error">File wajib bertipe pdf</Alert>) : ''}
              {nibHKIFile.name && nibHKIFile.type === "application/pdf" ? (<Alert severity="success">{nibHKIFile.name}</Alert>) : ''} */}

              {
                // Pastikan npwpHKIFile ada dan memiliki properti 'name' yang menandakan file telah diunggah
                nibHKIFile && nibHKIFile.name && (
                  // Pemeriksaan tipe file
                  nibHKIFile.type !== "application/pdf" ? (
                    <Alert severity="error">File wajib bertipe PDF</Alert>
                  ) : (
                    // Pemeriksaan ukuran file jika tipe file sudah sesuai
                    nibHKIFile.size > 5 * 1048576 ? (
                      <Alert severity="error">Ukuran file tidak boleh melebihi 5MB</Alert>
                    ) : (
                      // Umpan balik positif jika semua kondisi terpenuhi
                      <Alert severity="success">{nibHKIFile.name}</Alert>
                    )
                  )
                )
              }
              <Box sx={{ pt: 1.5, pb: 1, display: 'flex', alignItems: 'center' }}>
                <Button
                  variant="outlined"
                  component="span"
                  onClick={() => document.getElementById('kepemilikan-nib-hki-upload').click()}
                >
                  Tambahkan File
                </Button>
              </Box>
            </FormControl>
            <TextField error={inputHkiSinasError} helperText={inputHkiSinasError ? 'Wajib mengisi nomor Siinas' : ''} onChange={handleNoSinasHKIChange} value={noSinasHKI} fullWidth margin="normal" label="Nomor SIINas" variant="outlined" />
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel shrink htmlFor="kepemilikan-sinas-upload">Bukti Kepemilikan SIINas (*pdf maks 5Mb)</InputLabel>
              <input
                accept="application/pdf"
                id="kepemilikan-sinas-upload"
                type="file"
                style={{ display: 'none' }}
                onChange={handleHKISinasFileChange}
              />
              {/* {sinasHKIFile.type !== "application/pdf" && sinasHKIFile.length !== 0 ? (<Alert severity="error">File wajib bertipe pdf</Alert>) : ''}
              {sinasHKIFile.name && sinasHKIFile.type === "application/pdf" ? (<Alert severity="success">{sinasHKIFile.name}</Alert>) : ''} */}

              {
                // Pastikan npwpHKIFile ada dan memiliki properti 'name' yang menandakan file telah diunggah
                sinasHKIFile && sinasHKIFile.name && (
                  // Pemeriksaan tipe file
                  sinasHKIFile.type !== "application/pdf" ? (
                    <Alert severity="error">File wajib bertipe PDF</Alert>
                  ) : (
                    // Pemeriksaan ukuran file jika tipe file sudah sesuai
                    sinasHKIFile.size > 5 * 1048576 ? (
                      <Alert severity="error">Ukuran file tidak boleh melebihi 5MB</Alert>
                    ) : (
                      // Umpan balik positif jika semua kondisi terpenuhi
                      <Alert severity="success">{sinasHKIFile.name}</Alert>
                    )
                  )
                )
              }
              <Box sx={{ pt: 1.5, pb: 1, display: 'flex', alignItems: 'center' }}>
                <Button
                  variant="outlined"
                  component="span"
                  onClick={() => document.getElementById('kepemilikan-sinas-upload').click()}
                >
                  Tambahkan File
                </Button>
                {/* {sinasHKIFile && <Typography variant="body2" sx={{ ml: 2 }}>{sinasHKIFile.name}</Typography>} */}
              </Box>
            </FormControl>
            <TextField error={inputHkiNpwpError} helperText={inputHkiNpwpError ? 'Wajib mengisi nomor NPWP' : ''} onChange={handleNpwpHKIChange} value={npwpHKI} fullWidth margin="normal" label="Nomor NPWP" variant="outlined" />
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel shrink htmlFor="kepemilikan-npwp-upload">Bukti Kepemilikan NPWP (*pdf maks 5Mb)</InputLabel>
              <input
                accept="application/pdf"
                id="kepemilikan-npwp-upload"
                type="file"
                style={{ display: 'none' }}
                onChange={handleHKINpwpFileChange}
              />
              {/* {npwpHKIFile.length !== 0 && npwpHKIFile.type !== "application/pdf" ? setFileNpwpHkiError(true) : ''}
              {setFileNpwpHkiError ? (<Alert severity="error">File wajib bertipe pdf</Alert>) : ''}
              {npwpHKIFile.type === "application/pdf" && npwpHKIFile.length !== 0 && npwpHKIFile.size > 5 * 1048576 ? (<Alert severity="error">Ukuran file tidak boleh melebihi 5Mb</Alert>) : ''}
              {npwpHKIFile.name && npwpHKIFile.type === "application/pdf" ? (<Alert severity="success">{npwpHKIFile.name}</Alert>) : ''} */}
              {
                // Pastikan npwpHKIFile ada dan memiliki properti 'name' yang menandakan file telah diunggah
                npwpHKIFile && npwpHKIFile.name && (
                  // Pemeriksaan tipe file
                  npwpHKIFile.type !== "application/pdf" ? (
                    <Alert severity="error">File wajib bertipe PDF</Alert>
                  ) : (
                    // Pemeriksaan ukuran file jika tipe file sudah sesuai
                    npwpHKIFile.size > 5 * 1048576 ? (
                      <Alert severity="error">Ukuran file tidak boleh melebihi 5MB</Alert>
                    ) : (
                      // Umpan balik positif jika semua kondisi terpenuhi
                      <Alert severity="success">{npwpHKIFile.name}</Alert>
                    )
                  )
                )
              }
              <Box sx={{ pt: 1.5, pb: 1, display: 'flex', alignItems: 'center' }}>
                <Button
                  variant="outlined"
                  component="span"
                  onClick={() => document.getElementById('kepemilikan-npwp-upload').click()}
                >
                  Tambahkan File
                </Button>
                {/* {npwpHKIFile && <Typography variant="body2" sx={{ ml: 2 }}>{npwpHKIFile.name}</Typography>} */}
              </Box>
            </FormControl>
            <Typography variant="h8" sx={{ mt: 4, mb: 2 }}>
              Identitas Usaha (satu identitas difasilitasi hanya untuk satu etiket/merek)
            </Typography>
            <TextField error={inputHkiIdentitasError} helperText={inputHkiIdentitasError ? 'Wajib mengisi Identitas usaha' : ""} onChange={handleIdentitasUsahaHKIChange} value={identitasUsahaHKI} fullWidth margin="normal" label="Identitas Usaha (Email usaha)" variant="outlined" />
          </React.Fragment>
        ) :
          (
            // nonhki
            <React.Fragment>
              <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                Identitas Usaha
              </Typography>

              <TextField onChange={handleNoNibChange} value={noNib} fullWidth margin="normal" label="NIB (jika ada)" variant="outlined" />
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel shrink htmlFor="kepemilikan-nib-upload">Bukti Kepemilikan NIB (jika ada) (*pdf maks 5Mb)</InputLabel>
                <input
                  accept="application/pdf"
                  id="kepemilikan-nib-upload"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleNibFileChange}
                />
                {/* {nibFile.type !== "application/pdf" && nibFile.length !== 0 ? (<Alert severity="error">File wajib bertipe pdf</Alert>) : ''}
                {nibFile.name && nibFile.type === "application/pdf" ? (<Alert severity="success">{nibFile.name}</Alert>) : ''} */}
                {
                  // Pastikan npwpHKIFile ada dan memiliki properti 'name' yang menandakan file telah diunggah
                  nibFile && nibFile.name && (
                    // Pemeriksaan tipe file
                    nibFile.type !== "application/pdf" ? (
                      <Alert severity="error">File wajib bertipe PDF</Alert>
                    ) : (
                      // Pemeriksaan ukuran file jika tipe file sudah sesuai
                      nibFile.size > 5 * 1048576 ? (
                        <Alert severity="error">Ukuran file tidak boleh melebihi 5MB</Alert>
                      ) : (
                        // Umpan balik positif jika semua kondisi terpenuhi
                        <Alert severity="success">{nibFile.name}</Alert>
                      )
                    )
                  )
                }
                <Box sx={{ pt: 1.5, pb: 1, display: 'flex', alignItems: 'center' }}>
                  <Button
                    variant="outlined"
                    component="span"
                    onClick={() => document.getElementById('kepemilikan-nib-upload').click()}
                  >
                    Tambahkan File
                  </Button>
                  {nibFile && <Typography variant="body2" sx={{ ml: 2 }}>{nibFile.name}</Typography>}
                </Box>
              </FormControl>
              <TextField onChange={handleKbliChange} value={kbli} fullWidth margin="normal" label="Kode KBLI (jika ada)" variant="outlined" />
              <TextField error={inputNamaUsahaError} onChange={handleNamaUsahaChange} value={namaUsaha} fullWidth margin="normal" label="Nama Usaha" variant="outlined" helperText={inputNamaUsahaError ? 'Wajib mengisi nama usaha' : ''} />
              <TextField error={inputJenisProdukError} helperText={inputJenisProdukError ? "Wajib mengisi jenis produk" : ''} onChange={handleJenisProdukChange} value={jenisProduk} fullWidth margin="normal" label="Jenis Produk" variant="outlined" required />
              <TextField error={inputAlamatUsahaError} helperText={inputAlamatUsahaError ? "Wajib mengisi alamat usaha" : ''} onChange={handleAlamatUsahaChange} value={alamatUsaha} fullWidth margin="normal" label="Alamat Usaha" variant="outlined" required />
              <Typography variant="h6" sx={{ mt: 4, mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                Kategori Produksi
                <IconButton onClick={handleOpenProduksiModal}>
                  <AddIcon /> {/* Button untuk membuka modal */}
                </IconButton>
              </Typography>
              {errorKategoriProduksi && (
                <Typography variant="body2" sx={{ mt: 2, color: 'red' }}>
                  Klik tombol + untuk melengkapi rincian produksi.
                </Typography>
              )}
              {suksesKategoriProduksi && (
                <Box sx={{ mt: 2, mb: 2, p: 2, backgroundColor: '#E0F7FA', borderRadius: '4px' }}>
                  <Typography variant="body1" sx={{ color: '#00796B' }}>
                    Rincian produksi telah diisi, klik + untuk memperbarui.
                  </Typography>
                </Box>
              )}


              <Modal disableEscapeKeyDown open={openProduksiModal}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    handleCloseProduksiModal(); // Ini akan menutup modal hanya jika bukan karena klik backdrop
                  }
                }}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box sx={modalStyle}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography id="modal-title" variant="h6" component="h2">
                      Kategori Produksi
                    </Typography>
                    <Button onClick={handleCloseProduksiModal}>Tutup</Button>
                  </Box>
                  <TableContainer component={Paper} style={{ overflowX: 'auto', maxHeight: 440 }}>
                    <Table stickyHeader aria-label="Tabel Produksi">
                      <TableHead>
                        <TableRow>
                          <TableCell>NO</TableCell>
                          <TableCell sx={{ width: '50%', minWidth: 100 }}>KOMODITI</TableCell>
                          <TableCell sx={{ width: '50%', minWidth: 100 }}>KODE CABANG</TableCell>
                          <TableCell sx={{ width: '50%', minWidth: 100 }}>KBLI</TableCell>
                          <TableCell sx={{ width: '50%', minWidth: 100 }}>VOLUME PRODUKSI</TableCell>
                          <TableCell sx={{ width: '50%', minWidth: 100 }}>SATUAN</TableCell>
                          <TableCell sx={{ width: '50%', minWidth: 100 }}>NILAI PRODUKSI (Rp.000)</TableCell>
                          <TableCell>Hapus</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {produksiRows.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {index + 1}
                            </TableCell>
                            <TableCell>
                              <TextField sx={{ width: '50%', minWidth: 100 }} size="small" value={row.komoditi} onChange={(e) => handleChangeProduksi(index, 'komoditi', e.target.value)} />
                            </TableCell>
                            <TableCell>
                              <TextField sx={{ width: '50%', minWidth: 100 }} size="small" value={row.kodeCabang} onChange={(e) => handleChangeProduksi(index, 'kodeCabang', e.target.value)} />
                            </TableCell>
                            <TableCell>
                              <TextField sx={{ width: '50%', minWidth: 100 }} size="small" value={row.kbli} onChange={(e) => handleChangeProduksi(index, 'kbli', e.target.value)} />
                            </TableCell>
                            <TableCell>
                              <TextField sx={{ width: '50%', minWidth: 100 }} size="small" value={row.satuan} onChange={(e) => handleChangeProduksi(index, 'satuan', e.target.value)} />
                            </TableCell>
                            <TableCell>
                              <TextField sx={{ width: '50%', minWidth: 100 }} size="small" value={row.volumeProduksi} onChange={(e) => handleChangeProduksi(index, 'volumeProduksi', e.target.value)} />
                            </TableCell>
                            <TableCell>
                              <TextField sx={{ width: '50%', minWidth: 100 }} size="small" value={row.nilaiProduksi} onChange={(e) => handleChangeProduksi(index, 'nilaiProduksi', e.target.value)} />
                            </TableCell>
                            <TableCell>
                              <IconButton
                                aria-label="delete"
                                size="small"
                                onClick={() => handleRemoveProduksiRow(index)}
                                disabled={produksiRows.length === 1} // Tombol hapus dinonaktifkan jika hanya satu baris
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Button startIcon={<AddIcon />} onClick={addProduksiRow}>Tambah Baris</Button>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<SaveIcon />}
                      onClick={handleSaveProduksiData} // Anda perlu membuat fungsi ini
                    >
                      Simpan Data
                    </Button>
                  </Box>
                </Box>
              </Modal>

              <Typography variant="h6" sx={{ mt: 4, mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                Kategori Bahan Baku / Bahan Penolong (BB/BP)
                <IconButton onClick={handleOpenBbbpModal}>
                  <AddIcon /> {/* Button untuk membuka modal */}
                </IconButton>
              </Typography>
              {/* modal bbbp */}
              {errorKategoriBbbp && (
                <Typography variant="body2" sx={{ mt: 2, color: 'red' }}>
                  Klik tombol + untuk melengkapi rincian bahan.
                </Typography>
              )}
              {suksesKategoriBbbp && (
                <Box sx={{ mt: 2, mb: 2, p: 2, backgroundColor: '#E0F7FA', borderRadius: '4px' }}>
                  <Typography variant="body1" sx={{ color: '#00796B' }}>
                    Rincian bahan telah diisi, klik + untuk memperbarui.
                  </Typography>
                </Box>
              )}
              {/* {errorKategoriBbbp && bbBpRows.every(row => row.jenis && row.satuan && row.volume && row.harga) ? (
                <Typography variant="body2" sx={{ mt: 2, color: 'red' }}>
                  Klik tombol + untuk melengkapi rincian bahan.
                </Typography>
              ) : produksiRows.every(row => row.komoditi && row.kodeCabang && row.kbli && row.volumeProduksi && row.nilaiProduksi) ? (
                <Box sx={{ mt: 2, mb: 2, p: 2, backgroundColor: '#E0F7FA', borderRadius: '4px' }}>
                  <Typography variant="body1" sx={{ color: '#00796B' }}>
                    Rincian bahan telah diisi, klik + untuk memperbarui.
                  </Typography>
                </Box>
              ) : null} */}
              <Modal disableEscapeKeyDown open={openBbbpModal}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    handleCloseBbbpModal(); // Ini akan menutup modal hanya jika bukan karena klik backdrop
                  }
                }}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box sx={modalStyle}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography id="modal-title" variant="h6" component="h2">
                      Kategori Bahan Baku / Bahan Penolong (BB/BP)
                    </Typography>
                    <Button onClick={handleCloseBbbpModal}>Cancel</Button>
                  </Box>
                  <TableContainer component={Paper} style={{ overflowX: 'auto', maxHeight: 440 }}>
                    <Table stickyHeader aria-label="Tabel BB/BP">
                      <TableHead>
                        <TableRow>
                          <TableCell>NO</TableCell>
                          <TableCell>Jenis Bahan</TableCell>
                          <TableCell>SATUAN</TableCell>
                          <TableCell>VOL BB/BP</TableCell>
                          <TableCell>Nilai (Rp.000)</TableCell>
                          <TableCell>Hapus</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {bbBpRows.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {index + 1}
                            </TableCell>
                            <TableCell>
                              <FormControl fullWidth margin="normal">
                                <InputLabel id={`jenis-bahan-label-${index}`}>Jenis</InputLabel>
                                <Select
                                  labelId={`jenis-bahan-label-${index}`}
                                  id={`jenis-bahan-select-${index}`}
                                  value={row.jenis}
                                  onChange={(e) => handleChangeBbbp(index, 'jenis', e.target.value)}
                                  label="Jenis"
                                  sx={{ width: '20%', minWidth: 100 }}
                                >
                                  <MenuItem value="bb">BB</MenuItem>
                                  <MenuItem value="bp">BP</MenuItem>
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <TextField sx={{ width: '50%', minWidth: 100 }} size="small" value={row.satuan} onChange={(e) => handleChangeBbbp(index, 'satuan', e.target.value)} />
                            </TableCell>
                            <TableCell>
                              <TextField sx={{ width: '50%', minWidth: 100 }} size="small" value={row.volume} onChange={(e) => handleChangeBbbp(index, 'volume', e.target.value)} />
                            </TableCell>
                            <TableCell>
                              <TextField sx={{ width: '50%', minWidth: 100 }} size="small" value={row.harga} onChange={(e) => handleChangeBbbp(index, 'harga', e.target.value)} />
                            </TableCell>
                            <TableCell>
                              <IconButton
                                aria-label="delete"
                                size="small"
                                onClick={() => handleRemoveBbbpRow(index)}
                                disabled={bbBpRows.length === 1} // Tombol hapus dinonaktifkan jika hanya satu baris
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Button startIcon={<AddIcon />} onClick={addBbbpRow}>Tambah Baris</Button>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<SaveIcon />}
                      onClick={handleSaveBbbpData} // Anda perlu membuat fungsi ini
                    >
                      Simpan Data
                    </Button>
                  </Box>
                </Box>
              </Modal>
              <Typography variant="h6" sx={{ mt: 4, mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                Kategori Tenaga Kerja
                <IconButton onClick={handleOpenNakerModal}>
                  <AddIcon /> {/* Button untuk membuka modal */}
                </IconButton>
              </Typography>
              {/* modal tenaga kerja naker */}
              {errorKategoriNaker && (
                <Typography variant="body2" sx={{ mt: 2, color: 'red' }}>
                  Klik tombol + untuk melengkapi rincian tenaga kerja.
                </Typography>
              )}
              {suksesKategoriNaker && (
                <Box sx={{ mt: 2, mb: 2, p: 2, backgroundColor: '#E0F7FA', borderRadius: '4px' }}>
                  <Typography variant="body1" sx={{ color: '#00796B' }}>
                    Rincian tenaga kerja telah diisi, klik + untuk memperbarui.
                  </Typography>
                </Box>
              )}
              {/* {errorKategoriNaker && nakerRows.every(row =>
                (row.jenis && row.jumlahpria && row.upahpria) || (row.jenis && row.jumlahwanita && row.upahwanita)) ? (
                <Typography variant="body2" sx={{ mt: 2, color: 'red' }}>
                  Klik tombol + untuk melengkapi rincian tenaga kerja.
                </Typography>
              ) : produksiRows.every(row => row.komoditi && row.kodeCabang && row.kbli && row.volumeProduksi && row.nilaiProduksi) ? (
                <Box sx={{ mt: 2, mb: 2, p: 2, backgroundColor: '#E0F7FA', borderRadius: '4px' }}>
                  <Typography variant="body1" sx={{ color: '#00796B' }}>
                    Rincian tenaga kerja telah diisi, klik + untuk memperbarui.
                  </Typography>
                </Box>
              ) : null} */}
              <Modal
                disableEscapeKeyDown
                open={openNakerModal}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    handleCloseNakerModal();
                  }
                }}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box sx={modalStyle}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography id="modal-title" variant="h6" component="h2">
                      Kategori Tenaga Kerja<br></br>
                      <Typography variant="body2">isi 0 (nol) jika tidak ada</Typography>
                    </Typography>
                    <Button onClick={handleCloseNakerModal}>Cancel</Button>
                  </Box>
                  <TableContainer component={Paper} style={{ overflowX: 'auto', maxHeight: 440 }}>
                    <Table stickyHeader aria-label="Tabel Tenaga Kerja">
                      <TableHead>
                        <TableRow>
                          <TableCell>NO</TableCell>
                          <TableCell>Jenis</TableCell>
                          <TableCell>Jumlah Pria</TableCell>
                          <TableCell>Upah Pria (3 bulan)</TableCell> {/* Contoh menambahkan lebar kolom */}
                          <TableCell>Jumlah Wanita</TableCell>
                          <TableCell>Upah Wanita (3 bulan)</TableCell>
                          <TableCell>Hapus</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {nakerRows.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">{index + 1}</TableCell>
                            <TableCell sx={{ width: '50%', minWidth: 100 }}>
                              <FormControl fullWidth margin="normal">
                                <InputLabel id={`jenis-naker-label-${index}`}>Jenis Naker</InputLabel>
                                <Select
                                  labelId={`jenis-naker-label-${index}`}
                                  id={`jenis-naker-select-${index}`}
                                  value={row.jenis}
                                  onChange={(e) => handleChangeNaker(index, 'jenis', e.target.value)}
                                  label="Jenis Naker"
                                >
                                  <MenuItem value="wni">WNI</MenuItem>
                                  <MenuItem value="wna">WNA</MenuItem>
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <TextField size="small" value={row.jumlahpria} onChange={(e) => handleChangeNaker(index, 'jumlahpria', e.target.value)} />
                            </TableCell>
                            <TableCell>
                              <TextField sx={{ width: '30%', minWidth: 100 }} size="small" value={row.upahpria} onChange={(e) => handleChangeNaker(index, 'upahpria', e.target.value)} />
                            </TableCell>
                            <TableCell>
                              <TextField size="small" value={row.jumlahwanita} onChange={(e) => handleChangeNaker(index, 'jumlahwanita', e.target.value)} />
                            </TableCell>
                            <TableCell>
                              <TextField sx={{ width: '30%', minWidth: 100 }} size="small" value={row.upahwanita} onChange={(e) => handleChangeNaker(index, 'upahwanita', e.target.value)} />
                            </TableCell>
                            <TableCell>
                              <IconButton
                                aria-label="delete"
                                size="small"
                                onClick={() => handleRemoveNakerRow(index)}
                                disabled={nakerRows.length === 1}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Button startIcon={<AddIcon />} onClick={addNakerRow}>Tambah Baris</Button>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<SaveIcon />}
                      onClick={handleSaveNakerData}
                    >
                      Simpan Data
                    </Button>
                  </Box>
                </Box>
              </Modal>



              <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                Kategori Pemakaian Energi dalam 3 bulan
              </Typography>

              <TextField error={inputHargaMesinError} helperText={inputHargaMesinError ? "Wajib mengisi harga mesin" : ""} onChange={handleHargaMesinChange} value={hargaMesin} fullWidth margin="normal" label="Harga Mesin" variant="outlined" type="number" />
              <TextField error={inputHargaBbmError} helperText={inputHargaBbmError ? "Wajib mengisi harga bbm" : ""} onChange={handleHargaBbmChange} value={hargaBbm} fullWidth margin="normal" label="Harga BBM (3 bulan)" variant="outlined" type="number" />
              <TextField error={inputHargaListrikError} helperText={inputHargaListrikError ? "Wajib mengisi harga listrik" : ""} onChange={handleHargaListrikChange} value={hargaListrik} fullWidth margin="normal" label="Harga Listrik (3 bulan)" variant="outlined" type="number" />

            </React.Fragment>

          )
    },
    {
      label: 'Tahap akhir',
      content: `Setelah mengkllik selesai, data yang terinput akan otomatis terekam di sistem kami, pastikan data yang anda input sudah benar`,
    },
  ];



  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <React.Fragment>
            <FormControl fullWidth margin="normal">
              <InputLabel id="layanan-label">Pilih Layanan</InputLabel>
              <Select

                labelId="layanan-label"
                id="layanan-select"
                value={layanan}
                onChange={handleLayananChange}
                label="Pilih Layanan"
              >
                <MenuItem value="Pelatihan">Pelatihan</MenuItem>
                <MenuItem value="Fasilitasi">Fasilitasi</MenuItem>
                <MenuItem value="Sertifikasi">Sertifikasi</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal" disabled={!layanan}>
              <InputLabel id="tipe-label">Pilih Tipe</InputLabel>
              <Select
                labelId="tipe-label"
                id="tipe-select"
                value={tipe}
                onChange={handleTipeChange}
                label="Pilih Tipe"
              >
                {layanan && tipeOptions[layanan].map((option) => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {layanan === 'Pelatihan' && tipe === 'Proses Produksi' && (
              <FormControl fullWidth margin="normal">
                <InputLabel id="tipe-produksi-label">Tipe Produksi</InputLabel>
                <Select
                  labelId="tipe-produksi-label"
                  id="tipe-produksi-select"
                  value={tipeProduksi}
                  onChange={handleTipeProduksiChange}
                  label="Tipe Produksi"
                >
                  {tipeProduksiOptions.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </React.Fragment>
        );
      case 1:
        return 'up contains oneAn ad gro or more ads which target a shared set of keywords.';
      case 2:
        return `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`;
      default:
        return 'Unknown step';
    }
  }
  useEffect(() => {
    console.log('user berada di step', activeStep);
    console.log('ini data biasa', formData);
    console.log('ini produksi rows', produksiRows, produksiRows.length)
    console.log('ini data hki', dataHKI);
    const handleResize = () => {
      const width = window.innerWidth;

      if (width < 768) {
        console.log('Tampilan HP');
      } else if (width >= 768 && width < 1024) {
        console.log('Tampilan Tablet');
      } else {
        console.log('Tampilan Laptop/Desktop');
      }
    };

    // Jalankan ketika komponen dimuat
    handleResize();

    // Tambahkan event listener
    window.addEventListener('resize', handleResize);

    // Bersihkan event listener ketika komponen di-unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [produksiRows, activeStep, noNib, formData, produksiRows, bbBpRows, dataHKI]);
  return (
    <Box className="box__stepper" sx={{ p: 1, maxWidth: 400 }}>
      {/* // <ThemeProvider theme={customTheme}> */}
      {/* <Card sx={{ minWidth: 275, backgroundColor: '#2C575D' }}>
        <CardContent>
          <Typography variant="h6" component="div" sx={{ color: '#fff' }}>
            Waitlist IKM Bidang Perindustrian
          </Typography>
          <Typography variant="body2" sx={{ color: '#fff' }}>
            <LockIcon sx={{ color: '#E1E1E1' }} /> Data otomatis tersimpan di sistem kami

          </Typography>
        </CardContent>
      </Card> */}
      {/* <LinearProgressWithLabel value={progress} /> */}

      <Stepper activeStep={activeStep} orientation="vertical" >
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel optional={index === steps.length - 1 ? <Typography variant="caption"></Typography> : null}>
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography component={'span'}>{step.content}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                    {index === steps.length - 1 ? 'Kirim' : 'Lanjutkan'}
                  </Button>
                  <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                    Kembali
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {loading &&
        <LinearProgress
        />}
      {!loading && showRetry && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>{!navigator.onLine ? 'Harap periksa koneksi internet' : "Terjadi masalah pengiriman"}</Typography>
          

          <Button variant="contained" onClick={kirimDataHki} sx={{ mt: 1, mr: 1 }}>
            Kirim Ulang
          </Button>
          
        </Paper>

      )}

      {error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Silahkan memilih kategori layanan
        </Alert>

      )}

      {loading === false && activeStep === steps.length && !showRetry && !errorKirim && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>Semua data telah berhasil disimpan di sistem kami, terimakasih atas partisipasinya.</Typography>
        </Paper>
      )}
    </Box>
  )
}



