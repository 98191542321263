import React, { useState } from 'react';
import { Modal, Box, Button, TextField, Typography } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function Pengaduan() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nik, setNik] = useState('');
  const [nama, setNama] = useState('');

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleToWhatsApp = () => {
    const waMessage = `https://wa.me/6285608467500?text=NIK: ${nik}%0ANAMA: ${nama}%0A%0APertanyaan:%0A`;
    window.open(waMessage, '_blank');
    handleCloseModal();
  };

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleOpenModal}
        sx={{
          backgroundColor: '#25D366',
          color: '#ffffff',
          padding: '10px 20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%', // Full width on all devices for simplicity
          fontSize: '1rem',
          textTransform: 'none',
          borderRadius: '4px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          '&:hover': {
            backgroundColor: '#1ebe5f',
            boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
          },
          transition: 'background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out'
        }}
      >
        <WhatsAppIcon sx={{ mr: 1 }} />
        Layanan Pengaduan
      </Button>

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 300, bgcolor: 'background.paper', p: 4, borderRadius: 2 }}>
          <Typography id="modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
            Masukkan Data Anda
          </Typography>
          <TextField
            fullWidth
            label="NIK"
            value={nik}
            onChange={(e) => setNik(e.target.value)}
            margin="normal"
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Nama Lengkap"
            value={nama}
            onChange={(e) => setNama(e.target.value)}
            margin="normal"
            variant="outlined"
          />
          <Button
            onClick={handleToWhatsApp}
            color="primary"
            variant="contained"
            sx={{
              mt: 2, // Margin top
              width: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' },
              maxWidth: { lg: '500px', xl: '600px' }, // Misalnya, batasi lebar maksimum pada layar besar
              
              padding: { xs: '10px 20px', md: '12px 24px' }, // Larger padding on larger screens
              fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' }, // Increasing font size for larger screens
              backgroundColor: '#25D366', // Specific green color
              color: 'white', // White text color
              textTransform: 'none', // Normal text capitalization
              borderRadius: '4px', // Rounded corners
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
              '&:hover': {
                backgroundColor: '#1ebe5f', // Darker green on hover
                boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)', // Deeper shadow on hover
              },
              transition: 'all 0.3s ease-in-out', // Smooth transition for all properties
            }}
          >
            Masuk Live Chat
          </Button>

        </Box>
      </Modal>
    </div>
  );
}

export default Pengaduan;
