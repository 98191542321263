// Import React and Material-UI components
import React from 'react';
import { Box, Grid, Typography, Link as MuiLink } from '@mui/material';

const CustomLink = ({ children, href }) => {
    const handleClick = (event) => {
      event.preventDefault();
      window.open(href, '_blank');
    };
  
    return (
      <MuiLink href={href} onClick={handleClick} color="inherit">
        {children}
      </MuiLink>
    );
};

function Footer() {
  return (
    <Box component="footer" sx={{ width: '100%', bgcolor: 'primary.dark', color: 'white', p: 3 }}>
      <Grid container spacing={2}>
       
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" component="div">Hubungi Kami</Typography>
          <CustomLink href="https://www.facebook.com/profile.php?id=100072194706946">Facebook</CustomLink><br />
          <CustomLink href="mailto:jombangperindustrian@gmail.com">Email</CustomLink><br />
          <CustomLink href="https://www.instagram.com/disdagrin/">Instagram</CustomLink>
        </Grid>
      </Grid>
      <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
        © 2024 Bidang Perdagangan dan Perindustrian Kabupaten Jombang. All rights reserved.
      </Typography>
    </Box>
  );
}

export default Footer;
