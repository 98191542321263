import React, { useState, useEffect } from 'react';
import '../src/Custom.css'
import StepperForm from './components/StepperForm';
import { Box, TextField, CardContent, Button, Typography, Card, createTheme, ThemeProvider } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
// import Helper from './components/Helper';
import Pengaduan from './components/Pengaduan';
import Footer from './components/Footer';
function App() {
  const [hpView, setHpView] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    nik: '',
    nama: '',
    alamat: '',
    nowa: '',
  });
  const [formErrors, setFormErrors] = useState({
    nik: false,
    nama: false,
    alamat: false,
    nowa: false,
  });
  const [fieldFocus, setFieldFocus] = useState({
    nik: false,
    nama: false,
    alamat: false,
    nowa: false,
  });
  const myTheme = createTheme({
    typography: {
      fontFamily: [
        'Poppins',
        'Space-Grotesk',
        'sans-serif'
      ].join(','),
      color: 'red'
    }
  })

  const handleFocus = (name) => {
    setFieldFocus({ ...fieldFocus, [name]: true });
  };

  const handleBlur = (name) => {
    setFieldFocus({ ...fieldFocus, [name]: false });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the input name is 'nik' and restrict its length to 16 digits
    if (name === "nik") {
      const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
      if (numericValue.length <= 16) {
        setFormData({ ...formData, [name]: numericValue });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }

    setFormErrors({ ...formErrors, [name]: false });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {
      nik: formData.nik === '',
      nama: formData.nama === '',
      alamat: formData.alamat === '',
      nowa: formData.nowa === '',
    };

    setFormErrors(errors); // Update the error state

    // Determine if there are any errors
    const hasErrors = Object.values(errors).some(isError => isError);

    if (hasErrors) {
      console.log('Form validation failed. Please fill out all the fields.');
      return; // Prevent form submission if there are errors
    }

    console.log(formData); // If validation passes, proceed with form submission
    setFormSubmitted(true);
  };


  useEffect(() => {

    const handleResize = () => {
      const width = window.innerWidth;

      if (width < 768) {
        console.log('Tampilan HP');
        setHpView(true)
      } else if (width >= 768 && width < 1024) {
        console.log('Tampilan Tablet');
        setHpView(false)
      } else {
        console.log('Tampilan Laptop/Desktop');
        setHpView(false)

      }
    };

    console.log(formData)
    // Jalankan ketika komponen dimuat
    handleResize();

    // Tambahkan event listener
    window.addEventListener('resize', handleResize);

    // Bersihkan event listener ketika komponen di-unmount
    return () => window.removeEventListener('resize', handleResize);
    // const generateRandomString = function (length, randomString="") {
    //   randomString += Math.random().toString(20).substr(2, length);
    //   if (randomString.length > length) return randomString.slice(0, length);
    //   return generateRandomString(length, randomString);
    // };

    // console.log(generateRandomString(80,100))
  }, [formData])
  if (formSubmitted) {
    return (
      <div id='my__container'>
        <StepperForm
          dataNik={formData.nik}
          dataNama={formData.nama}
          dataAlamat={formData.alamat}
          dataNowa={formData.nowa}
        />
        {/* <Helper /> */}
      </div>

    )
  }

  return (
    <ThemeProvider theme={myTheme}>
      <Box className="my__box__container" sx={{ maxWidth: hpView ? 400 : 900, margin: 'auto', mt: 5, p: hpView ? 2 : 4 }}>
        {/* <Paper square elevation={0} sx={{ backgroundColor: '#2C575D', p: 3 }}>
        <Typography variant="h6" component="div" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#fff' }}>
          Waitlist IKM Bidang Perindustrian<br></br><br></br>
        </Typography>
        <Typography variant="body2" sx={{ color: '#fff' }}>
          <LockIcon sx={{ color: '#E1E1E1', width: '.8rem' }} /> Data otomatis tersimpan di sistem kami
        </Typography>
      </Paper> */}
        {/* backgroundColor: '#2C575D' */}
        <Box sx={{ borderWidth: '0px', borderRadius: '0px', width: '100%', borderTop: '5px solid #2C575D', bgcolor: '#fff' }}>
          <Card variant="outlined" sx={{ borderRadius: '0px', minWidth: 275 }}>
            <CardContent>
              <Typography className="my__custom__component" variant="h5" component="div" sx={{ mb: 2, color: '#5f6368', textAlign: 'center' }}>
                Sistem Informasi Bina Industri Paripurna (SEMPURNA)
              </Typography>
              <Typography className="my__custom__component" variant="h5" component="div" sx={{ mb: 2, color: '#5f6368', textAlign: 'center' }}>
                Dinas Perdagangan dan Perindustrian
              </Typography>
              <LockIcon sx={{ color: '#5f6368', mb: 1 }} />
              <Typography variant="body2" sx={{ color: '#5f6368' }}>
                Data Anda akan otomatis tersimpan di sistem kami.
              </Typography>
            </CardContent>
          </Card>
        </Box>
        <form style={{ padding: '10px' }} onSubmit={handleSubmit}>
          <TextField
            error={formErrors.nik}
            fullWidth
            label="NIK"
            name="nik"
            value={formData.nik}
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
            placeholder="Masukkan nik 16 digit"
            onFocus={() => handleFocus('nik')}
            onBlur={() => handleBlur('nik')}
            maxLength={16}
            inputProps={{
              maxLength: 16, // Not effective for type='number', used for type='text'
              pattern: "\\d*", // Ensures only digits can be entered
              style: {
                color: '#333', // Warna placeholder and input text
                fontWeight: 600,
                '::placeholder': {
                  color: '#333', // Attempt to directly style placeholder, might not work as expected
                  opacity: 1, // Ensure placeholder's visibility
                  letterSpacing: '1px'
                },
              },
            }}
            InputLabelProps={{
              style: { color: '#333' },
            }}
            InputProps={{
              style: {
                color: 'rgba(0, 0, 0, 0.87)', // Warna teks input
              },
              inputProps: {
                style: {
                  color: '#454545', // Warna placeholder
                  fontWeight: 600,
                  letterSpacing: '1.5px'
                },
              },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: formErrors.nik ? '#f44336' : '#2C575D', // Membuat border lebih tegas
                  borderWidth: '1px', // Membuat border lebih tebal
                },
                '&:hover fieldset': {
                  borderColor: '#2C575D', // Menjaga kontras border saat hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2C575D', // Menjaga kontras border saat fokus
                  borderWidth: '1px', // Membuat border saat fokus lebih tebal
                },
                '& input::placeholder': {
                  color: '#333', // Correct way to ensure placeholder color
                  opacity: 1, // Ensure placeholder's visibility
                  fontWeight: 400
                },
                color: '#333', // Warna teks lebih gelap untuk kontras yang lebih tegas
                boxShadow: '0px 2px 4px rgba(0,0,0,0.1)', // Menambahkan bayangan ringan
              },
              mt: 2, // Jarak atas
            }}
            helperText={formErrors.nik ? "Wajib mengisi NIK." : ""}
          />
          <TextField
            error={formErrors.nama}
            fullWidth
            label="Nama Lengkap"
            name="nama"
            value={formData.nama}
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              style: { color: '#333' },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: formErrors.nama ? '#f44336' : '#2C575D', // Membuat border lebih tegas
                  borderWidth: '1px', // Membuat border lebih tebal
                },
                '&:hover fieldset': {
                  borderColor: '#2C575D', // Menjaga kontras border saat hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2C575D', // Menjaga kontras border saat fokus
                  borderWidth: '1px', // Membuat border saat fokus lebih tebal
                },
                '& input::placeholder': {
                  color: '#2C575D', // Menjaga kontras placeholder
                },
                color: '#333', // Warna teks lebih gelap untuk kontras yang lebih tegas
                boxShadow: '0px 2px 4px rgba(0,0,0,0.1)', // Menambahkan bayangan ringan
              },
              mt: 2, // Jarak atas
            }}
            InputProps={{
              inputProps: {
                style: {
                  color: '#454545', // Warna placeholder
                  fontWeight: '600'
                },
              },
            }}
            helperText={formErrors.nama ? "Wajib mengisi nama." : ""}
          />
          <TextField
            error={formErrors.alamat}
            fullWidth
            label="Alamat (sesuai KTP)"
            name="alamat"
            value={formData.alamat}
            onChange={handleInputChange}
            multiline
            rows={4}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              style: { color: '#333' },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: formErrors.alamat ? '#f44336' : '#2C575D', // Membuat border lebih tegas
                  borderWidth: '1px', // Membuat border lebih tebal
                },
                '&:hover fieldset': {
                  borderColor: '#2C575D', // Menjaga kontras border saat hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2C575D', // Menjaga kontras border saat fokus
                  borderWidth: '1px', // Membuat border saat fokus lebih tebal
                },
                '& input::placeholder': {
                  color: '#2C575D', // Menjaga kontras placeholder
                },
                color: '#333',
                boxShadow: '0px 2px 4px rgba(0,0,0,0.1)', // Menambahkan bayangan ringan
              },
              mt: 2, // Jarak atas
            }}
            InputProps={{
              inputProps: {
                style: {
                  color: '#454545', // Warna placeholder
                  fontWeight: '600'
                },
              },
            }}
            helperText={formErrors.alamat ? "Wajib mengisi Alamat." : ""}
          />
          <TextField
            error={formErrors.nowa}
            fullWidth
            label="Nomor WA/Telp"
            name="nowa"
            value={formData.nowa}
            placeholder="628 / 08"
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              style: { color: '#333' },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {

                  borderColor: formErrors.nowa ? '#f44336' : '#2C575D', // Membuat border lebih tegas
                  borderWidth: '1px', // Membuat border lebih tebal
                },
                '&:hover fieldset': {
                  borderColor: '#2C575D', // Menjaga kontras border saat hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2C575D', // Menjaga kontras border saat fokus
                  borderWidth: '1px', // Membuat border saat fokus lebih tebal
                },
                '& input::placeholder': {
                  color: '#2C575D', // Menjaga kontras placeholder
                },
                color: '#333', // Warna teks lebih gelap untuk kontras yang lebih tegas
                boxShadow: '0px 2px 4px rgba(0,0,0,0.1)', // Menambahkan bayangan ringan
              },
              mt: 2, // Jarak atas
            }}
            InputProps={{
              inputProps: {
                style: {
                  color: '#454545', // Warna placeholder
                  fontWeight: '600'
                },
              },
            }}
            helperText={formErrors.nowa ? "Wajib mengisi nomor whatsapp." : ""}
          />

          <Button type="submit" variant="contained" sx={{
            backgroundColor: '#2C575D',
            ':hover': {
              backgroundColor: '#274e53', // Sedikit lebih gelap saat di-hover
            },
            width: '100%',
            mt: 2,
            color: '#fff'
          }}>
            Submit
          </Button>
        </form>

      </Box>
      <Pengaduan />
      <Footer />
      {/* <Helper  /> */}


    </ThemeProvider>
  );
}





export default App;
